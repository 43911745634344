.container {
  min-height: 70vh;
}

.mail-body {
  line-height: 2.5rem;
}
.docViewer-modal {
  .modal-dialog {
    max-width: 70vw;
    height: 90vh;
    .modal-content {
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }
}

.tooltip .tooltip-inner {
  width: fit-content;
}
