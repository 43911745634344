/* コンテキストメニュー */
.ngx-contextmenu a {
  display: block;
  padding: 3px 20px;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
}

.ngx-contextmenu a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
