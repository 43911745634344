.btn {
  font-size: 1.3rem;
  padding: 0.6rem.9rem;
}

.btn-primary-dark {
  border: none;
  color: var(--btn-text-color);
  background: var(--btn-primary-color);

  &:hover {
    background: var(--btn-primary-color-hover);
  }
  &:disabled {
    background-color: var(--btn-tertiary-color);
  }
}

.btn:not(:disabled):not(.disabled).active,
.btn-primary-dark:not(:disabled):not(.disabled):active,
.show > .btn-primary-dark.dropdown-toggle:focus {
  color: $white;
  background: var(--btn-primary-color-hover);
  border: none;
}

.btn-dark {
  color: var(--btn-text-color);
  background: var(--btn-tertiary-color);
  border: none;

  &:hover {
    background: var(--btn-tertiary-color-hover);
  }
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: var(--btn-tertiary-color-hover);
  border: none;
}

.btn:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: var(--btn-tertiary-color-hover);
  border: none;
}

.btn-non-active {
  color: $black;
  background: var(--btn-primary-color);
  opacity: 0.2;
}

.btn-danger {
  color: var(--btn-text-color);
  background: var(--btn-secondary-color);
  border: none;

  &:hover {
    background: var(--btn-secondary-color-hover);
    border: none;
  }
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  background: var(--btn-secondary-color);
  border: none;
}

.btn-disabled {
  opacity: 0.65;
}

.main-part-button {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: underline;
  cursor: pointer;
}
