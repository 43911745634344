.footer-basic {
  padding: 40px 0;
  background-color: #ffffff;
  color: #4b4c4d;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

.flicker_icon {
  right: 0;
}

.flicker_icon {
  border-right: 30px solid transparent;
  border-bottom: 30px solid #d6d6d6;
}

.text-left.td_border_none {
}

table tr td a {
  display: block;
  height: 100%;
  width: 100%;
}

table tr td {
  padding-left: 0;
  padding-right: 0;
}

.position-top-left {
  top: 0;
  left: 0;
}

.position-top-right {
  right: 0;
  top: 0;
}

.scroll_list {
  /* height:837px !important;
  overflow-y:scroll; */
}

.link_list_hover:hover {
  /* background:#f9f9f9 !important; */
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

.link_list_hover {
  transition-duration: 0.3s;
  transition-delay: 0s;
}

.link_list_hover_green:hover {
  background: #f5fffa !important;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

.link_list_hover_green {
  transition-duration: 0.3s;
  transition-delay: 0s;
}

.accordion-close-button {
  top: 30px;
  right: 0;
}

a:hover {
  color: #ff7c7c;
  text-decoration: underline;
}

.bottom-right-button {
  bottom: 15px;
  right: 15px;
}

.link_list_hover_white {
  transition-duration: 0.3s;
  transition-delay: 0s;
}

.link_list_hover_white:hover {
  background: #ffffff !important;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.2);
}

.lists_table_padding_and_font_size > th {
  font-size: 13px;
  padding: 10px 4px 10px 8px;
}

.lists_table_padding_and_font_size > tr > td {
  font-size: 13px;
  padding: 10px 4px 10px 8px;
}

.container {
  width: 95%;
  max-width: 2000px;
}

.search_height_and_margin_bottom > div > div {
  height: 30px;
  margin-bottom: 10px;
}

.img-fluid {
  text-align: center;
}

.form-control-lg {
  font-size: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #aaa;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #7fc6b1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(28px);
  -ms-transform: translateX(28px);
  transform: translateX(28px);
}

.slider.round {
  border-radius: 34px;
}

input:checked + .slider {
  background-color: #7fc6b1;
}
