/*
    _mixins.scss
*/

// max-width
$breakpoint-max: (
  'sm': 'screen and (max-width: 600px)',
  'md': 'screen and (max-width: 800px)',
  'midd': 'screen and (max-width: 1280px)',
  'lg': 'screen and (max-width: 1470px)',
  'xl': 'screen and (max-width: 1600px)'
) !default;

// -- media query
@mixin mq-max($breakpoint: sm) {
  @media #{map-get($breakpoint-max, $breakpoint)} {
    @content;
  }
}

@mixin hover-darken-color($color) {
  &:hover {
    color: darken($color, 10%);
  }
}

@mixin active-darken-color($color) {
  &:active {
    color: darken($color, 20%);
  }
}

@mixin btn-darken-color($color) {
  @include hover-darken-color(($color));
  @include active-darken-color(($color));
}

@mixin hover-darken-background-color($color) {
  &:hover {
    background-color: darken($color, 10%);
  }
}

@mixin active-darken-background-color($color) {
  &:active {
    background-color: darken($color, 20%);
  }
}

@mixin btn-darken-background-color($color) {
  @include hover-darken-background-color(($color));
  @include active-darken-background-color(($color));
}
