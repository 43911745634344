html {
  font-size: 10px; /*ベースを10pxにしています*/

  @include mq-max(xl) {
    font-size: 9px;
  }

  @include mq-max(lg) {
    font-size: 8.5px;
  }

  @include mq-max(midd) {
    font-size: 8px;
  }
}

body {
  font-size: 1.4rem;
  min-width: 1000px;
  color: var(--text-color);
  background-color: var(--main-background-color);
}

body::-webkit-scrollbar {
  /* Chrome, Safari 対応 */
  display: none;
}

textarea {
  background-color: var(--main-background-color) !important;
  color: var(--text-color) !important;
}

select {
  background-color: var(--main-background-color) !important;
  color: var(--text-color) !important;
}

input:not([type='button']):not([type='submit']) {
  background-color: var(--main-background-color) !important;
  color: var(--text-color) !important;
}

.modal-header {
  color: var(--text-color);
  background-color: var(--main-background-color);
  border-bottom-color: var(--border-line-color);
}
.modal-body {
  color: var(--text-color);
  background-color: var(--main-background-color);
}
.modal-footer {
  color: var(--text-color);
  background-color: var(--main-background-color);
  border-top-color: var(--border-line-color);
}
