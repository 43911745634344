* {
  font-family: $hiragino;
}

a {
  color: #65b19a;
}

p {
  margin: 0;
}

h3 {
  margin: 0;
}

.h4,
h4 {
  margin: 0;
  font-size: 16px;
}

h5 {
  margin: 0;
}

hr {
  margin: 0.5rem 0 1rem;
}

// ページタイトル
.result-title {
  padding-top: 2.5rem;
  margin-top: 6rem;
  margin-bottom: 1.5rem;
  &_text {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
  }
}

label {
  margin: 0;
}

.highlight {
  background-color: var(--highlight-color);
}

.link {
  color: #65b19a;

  &:hover {
    color: #ff7c7c;
    text-decoration: underline;
  }
}

.font-weight-normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.strong {
  font-weight: bold;
  font-size: 1.5rem;
}
.matched {
  color: coral;
}

.weak {
  color: #777777;
}

a .fa, button .fa {
  cursor: pointer;
}

.icon_question {
  margin: 0 5px;
  color: #cfcfcf;
  font-size: 23px;
}

.pointer {
  cursor: pointer;
}

.ai_mail_id {
  position: absolute;
  font-size: 1rem;
  color: #ccc;
  bottom: 0.8rem;
  right: 1.5rem;
}

.ai_mail_id_top {
  top: 0.8rem;
  height: 1.2rem;
}

.matching_score {
  position: absolute;
  font-size: 1rem;
  color: #ccc;
  top: 10rem;
  right: 1.5rem;
}
