/*
    _Variables.scss
*/

// ----- Colors
$black: #000;
$white: #fff;
$grey: #777777;
$pink-dark: #e1a0b6;

$primary-color: #c7ede0;
$primary-dark-color: #65b19a;

// -- fonts
$hiragino: 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo,
  'Roboto', Osaka, 'ＭＳ Ｐゴシック', 'MS P Gothic', Verdana, sans-serif;

:root {
  --main-background-color: white;
  --text-color: black;
  --login-form-background-color: #f1f7fc;
  --login-icon-color: #c7ede0;
  --login-text-color: #6f7a85;
  --footer-background-color: #c7ede0;
  --footer-text-color: #4b4c4d;
  --footer-copyright-text-color: rgb(136, 136, 136);
  --menu-active-color: #e68c23;
  --menu-inactive-color: #707070;
  --search-tab-active-color: #000000;
  --search-tab-inactive-color: #999999;
  --component-background-color: white;
  --component-title-text-color: #707070;
  --component-title-background-color: #c7ede0;
  --loader-color: #0dc5c1;
  --border-line-color: #dee2e6;
  --list-hover-color: #eefffb;
  --nav-tab-activate-background-color: #e9f8f3;
  --nav-exit-color: #707070;
  --new-received-mail-text-color: white;
  --new-received-mail-background-color: #bbb;
  --auto-matching-tab-background-color: #f9f9f9;
  --logo-background-color: white;
  --logo-invert: invert(0%);
  --btn-primary-color: #65b19a;
  --btn-primary-color-hover: #509a84;
  --btn-secondary-color: #c96862;
  --btn-secondary-color-hover: #a55551;
  --btn-tertiary-color: #777777;
  --btn-tertiary-color-hover: #555555;
  --btn-text-color: white;
  --btn-search-section-textarea: rgb(255, 255, 255);
  --btn-search-section-textarea-hover: rgb(221, 221, 221);
  --qoala-light-pink: rgb(240, 128, 128);
  --qoala-light-blue: rgb(100, 149, 237);
  --qoala-light-green: rgb(103, 172, 101);
  --qoala-red-tag: rgb(200, 50, 50);
  --qoala-red-tag-hover: rgb(170, 40, 40);
  --qoala-blue-tag: rgb(45, 100, 215);
  --qoala-blue-tag-hover: rgb(30, 80, 200);
  --qoala-green-tag: rgb(30, 160, 30);
  --qoala-green-tag-hover: rgb(20, 140, 20);
  --quick-tag-text-color: white;
  --offering-tag-text-color: white;
  --offering-tag-color: #f29f3d;
  --registered-tag-text-color: white;
  --registered-tag-color: #777777;
  --matching-label-text-color: rgb(255, 255, 255);
  --term-of-service-before-login-background-color: white;
  --term-of-service-after-login-background-color: rgb(245, 245, 245);
  --close-button-color: rgb(110, 110, 110);
  --warning-color: rgb(247, 104, 104);
  --reject-text-color: rgb(180, 180, 180);
}
