.border-top-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.padding-top-bottom-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-50 {
  padding-top: 50px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-top-3 {
  margin-top: 3px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}
